import { environmentNames } from "./environmentNames";

export const environmentsMap = Object.freeze({
  ["mercplus-dev.maersk.com"]: environmentNames.DEV,
  ["mercplus-cdt.maersk.com"]: environmentNames.TEST,
  ["mercplus-stage.maersk.com"]: environmentNames.PREPROD,
  ["mercplus.maersk.com"]: environmentNames.PROD,

  ["aems-cdt.maersk.com"]: environmentNames.TEST,
  ["aems-stage.maersk.com"]: environmentNames.PREPROD,
  ["aems.maersk.com"]: environmentNames.PROD,
});
