<template>
  <div class="mds-rich-text">
    <p>{{ message }}</p>
  </div>
</template>

<script>
import { messages } from "./static"
import authorization from "../mixins/authorization";
import originalUrlRedirection from "../mixins/originalUrlRedirection";

export default {
  mixins: [originalUrlRedirection, authorization],

  data() {
    return {
      message: "",
    };
  },

  mounted() {
    this.saveOriginalUrl();

    if (!this.$al.storage.access_token) {
      this.removeCookies();
      this.login();
    } else if (this.isTokenExpired) {
      this.message = messages.redirect;
      this.removeCookies();
      this.login();
    } else {
      this.setCookies(this.$al.storage);
      this.redirectToOrginalUrlOr(this.redirectToTargetApplicaiton)
    }
  },
};
</script>
